<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="title d-flex flex-column">
      <div class="alltit d-flex flex-row">
        <div class="">
          <p >{{ $t("lucky_title_activity")}} </p>
          <img class="hovershow" src="@/assets/auction_rules.png" alt="" />
           <div class="box">
             <div v-html="TopInfoRule"></div>
           </div>
        </div>
      </div>
      <div class="cont d-flex flex-row justify-space-between">
        <div class="desc">{{ getChainNameByDenom(TopInfo.coinDenom)}} {{ $t("lucky_title_index") }}</div>
        <div class="linkto d-flex flex-row" v-if="!isMobile && this.$store.state.did" style=" cursor: pointer;"  @click="ToMyLuckyDraw">
          <span > {{ $t("headerUserCenterNavMyLuckyDraw") }}</span>
          <img :src="rightIcon" alt=""/>
        </div>
      </div>
    </div>
    <div
      class="
        progress
        mt-4
        mb-3
        d-flex
        flex-lg-row flex-column
        justify-space-between
      "
    >
      <div class="left">
        <p>{{TopInfo.activityName}}</p>
        <div class="pricetit">{{ $t("lucky_raffle_begin") }} </div>
        <div class="pricecont d-flex flex-lg-row flex-column">
           <img :src="
                require('@/assets/icons/' +
                  getChainByName(TopInfo.coinDenom)+
                  '.png')
          " />
          <div class="price">{{TopInfo.prizePool}}</div>
        </div>
      </div>
      <div class="center" @click="toLottery(TopInfo.id)" v-if="!isMobile && this.$store.state.did && TopInfo.joinNumber != 0">
       {{ $t("myLotteries") }}：{{ TopInfo.joinNumber }} >
      </div>
      <div class="right">
        <p> {{ $t("lucky_raffle_countdown") }}</p>
        <div class="timer d-flex flex-lg-row">
          <div class="days">
            <div class="top d-flex flex-row">
              <span class="mr-1">{{ d1 }}</span>
              <span class="mr-2">{{ d2 }}</span>
            </div>
            <div class="bom">{{$t("Day")}}</div>
          </div>
          <div class="mao">:</div>
          <div class="hours">
            <div class="top d-flex flex-row">
              <span class="mr-1 ml-2">{{ h1 }}</span> 
              <span class="mr-2">{{ h2 }}</span>
            </div>
            <div class="bom">{{$t("Hr")}}</div>
          </div>
          <div class="mao">:</div>
          <div class="minutes">
            <div class="top d-flex flex-row">
              <span class="ml-2 mr-1">{{ m1 }}</span>
              <span class="mr-2">{{ m2 }}</span>
            </div>
            <div class="bom">{{$t("Min")}}</div>
          </div>
          <div class="mao">:</div>
          <div class="seconds">
            <div class="top d-flex flex-row">
              <span class="ml-2 mr-1">{{ s1 }}</span>
              <span class="">{{ s2 }}</span>
            </div>
            <div class="bom">{{$t("Sec")}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isMobile && list.length > 0">
      <div
        class="list mb-3 d-flex flex-lg-row flex-column justify-space-between"
        v-for="item in list"
        :key="item.id"
        v-scroll="onScroll"
      >
        <div class="price  mr-lg-16 d-flex flex-column" style="width: 25%">
          <div class="pricetit">{{ item.activityName }}</div>
          <div class="d-flex flex-lg-row">
           
             <img   class="priceicon" :src="
                require('@/assets/icons/' +
                  getChainByName(item.coinDenom)+
                  '.png')
          " />
            <div class="pricenum">{{ item.prizePool }}</div>
          </div>
        </div>
        <div class="num  d-flex flex-column" style="width: 17%">
          <div style="line-height: 33px; margin-top: -20px; font-size:15px"> {{ $t("lucky_winner") }} </div>
          <div v-if="item.activityStatus == 2" style="font-size:15px;font-weight: bold;">{{ item.userName}}</div>
          <div
            v-if="item.activityStatus == 3"
            class="load d-flex flex-row "
          >
            <p class="mb-n6" style="width:150px; font-size:12px">  {{ $t("lucky_raffle_ing") }}</p>
           <img src="@/assets/loading.gif" alt="" class="mt-1" width="18px" height="18px"/>
          </div>
        </div>
        <div class="time  d-flex flex-lg-row" style="width: 20%">
          <img
            src="@/assets/image/pool_chianlink.png"
            alt=""
            width="31px"
            height="30px"
          />
          <div class="ml-2" style="font-size:15px;font-weight: bold;">{{ item.endTime }}</div>
        </div>
        <div
          v-if="item.activityStatus == 2"
          class="link d-flex flex-row mr-8"
          style="width: 2%"
        >
          <img
            src="@/assets/image/icon_link.png"
            alt="图1"
            width="23px"
            height="23px"
            style=" cursor: pointer;"
            @click="copyChainLink(item.txid)"
          />
          <img
            src="@/assets/image/icon_share1.png"
            alt="图2"
            class="ml-3"
            width="23px"
            height="23px"
            style=" cursor: pointer;"
            @click="shareBtn(item.id)"
          />
        </div>
        <div
          v-if="item.activityStatus == 3"
          class="link d-flex flex-row mr-8"
          style="width: 2%"
        ></div>
         <div class="share"  v-if="share_id==item.id">
            <div
              style=" cursor: pointer;"
              class="d-flex flex-row mt-lg-5 mt-5 pointer "
              :class="{ click: isCopy }"
              @click="share('copyLink', 'https://serviceiris.upticknft.com/sharing/'+item.id+'/lucky.png','','https://bscscan.com/tx/'+item.txid)"
            >
              <img
                class="mx-5"
                src="@/assets/icons/icon_IPFS.png"
                alt="Copy Link"
              />
              <span class="ml-0">
                {{ $t("carddetailShareCopyLink") }}
              </span>
            </div>
            <!-- <div class="d-flex flex-row mt-lg-6 mt-6 pointer " @click="toTweet"> -->
            <div
            style=" cursor: pointer;"
              class="d-flex flex-row mt-lg-6 mt-6 pointer"
              @click="share('twitter', 'https://serviceiris.upticknft.com/sharing/'+item.id+'/lucky.png','','https://bscscan.com/tx/'+item.txid)"
            >
              <img
                class="mx-5"
                src="@/assets/icons/icon_twitter.png"
                alt="Tweet"
              />
              <span class="ml-0">Twitter</span>
            </div>
            <div
            style=" cursor: pointer;"
              class="d-flex flex-row mt-lg-6 mb-lg-4 mt-6 mb-4 pointer"
              @click="share('facebook', sharePath)"
            >
              <v-icon class="mx-lg-5 ml-5 icon" color="primary">
                mdi-facebook
              </v-icon>
              <span class="ml-0 pr-7 mt-1">Facebook</span>
            </div>

          </div>
      </div>
    </div>
     <div v-if="!isMobile && list.length == 0" class="empty">{{ $t("EmptyHere") }}</div>
    <div v-if="isMobile" class="mobilelist">
      <div
        class="list1 mb-3 d-flex flex-lg-row flex-column justify-space-between"
        v-for="item in list"
        :key="item.id"
        v-scroll="onScroll"
      >
        <div class="top d-flex flex-row justify-space-between">
          <div class="price mr-lg-16 d-flex flex-column">
            <div class="pricetit">{{ item.activityName}}</div>
            <div class="d-flex flex-lg-row">
               <img   class="priceicon" :src="
                require('@/assets/icons/' +
                  getChainByName(item.coinDenom)+
                  '.png')
          " />
              <div class="pricenum">{{ item.prizePool }}</div>
            </div>
          </div>
          <div class="num mr-lg-n16 d-flex flex-column">
            <div style=" margin-top: -20px;font-size:12px;margin-bottom:12px;">{{ $t("lucky_winner") }}</div>
            <div v-if="item.activityStatus == 2" class="load">{{ item.userName }}</div>
            <div
              v-if="item.activityStatus == 3"
              class="load d-flex flex-row ml-n3"
            >
              <p>{{ $t("lucky_raffle_ing") }}</p>
              <img src="@/assets/loading.gif" alt="" />
            </div>
          </div>
        </div>
        <div class="bottom d-flex flex-row justify-space-between">
          <div class="time mr-lg-n16 d-flex flex-lg-row">
            <img
              src="@/assets/image/pool_chianlink.png"
              alt=""
              width="31px"
              height="30px"
            />
            <div>{{ item.endTime }}</div>
          </div>
          <div class="link d-flex flex-row" v-if="item.activityStatus == 2">
            <img
              src="@/assets/image/icon_link.png"
              alt="图1"
              width="23px"
              height="23px"
              @click="copyChainLink(item.txid)"
            />
            <img
              src="@/assets/image/icon_share1.png"
              alt="图2"
              class="ml-3"
              width="23px"
              height="23px"
               @click="shareBtn(item.id)"
            />
          </div>
          <div
            v-if="item.activityStatus== 3"
            class="link d-flex flex-row"
          ></div>
           <div class="share"  v-if="share_id==item.id">
            <div
              class="d-flex flex-row mt-lg-5 mt-5 pointer"
              :class="{ click: isCopy }"
              @click="share('copyLink', 'https://serviceiris.upticknft.com/sharing/'+item.id+'/lucky.png','','https://bscscan.com/tx/'+item.txid)"
            >
              <img
                class="mx-5"
                src="@/assets/icons/icon_IPFS.png"
                alt="Copy Link"
              />
              <span class="ml-0">
                {{ $t("carddetailShareCopyLink") }}
              </span>
            </div>
            <!-- <div class="d-flex flex-row mt-lg-6 mt-6 pointer " @click="toTweet"> -->
            <div
              class="d-flex flex-row mt-lg-6 mt-6 pointer"
              @click="share('twitter', 'https://serviceiris.upticknft.com/sharing/'+item.id+'/lucky.png','','https://bscscan.com/tx/'+item.txid)"
            >
              <img
                class="mx-5"
                src="@/assets/icons/icon_twitter.png"
                alt="Tweet"
              />
              <span class="ml-0">Twitter</span>
            </div>
            <div
              class="d-flex flex-row mt-lg-6 mb-lg-4 mt-6 mb-4 pointer"
              @click="share('facebook', sharePath)"
            >
              <v-icon class="mx-lg-5 ml-5 icon mx-5 " color="primary">
                mdi-facebook
              </v-icon>
              <span class="ml-0 pr-7 mt-1">Facebook</span>
            </div>

          </div>
        </div>
      </div>
    </div>
    <loading :isShowLoading="isShowLoading" ></loading>
    <div
      v-if="openlottery"
      style="position: fixed; right: 20px;top:0px; bottom: -20px; z-index: 99999"
    >
      <!-- <div v-if="openlottery" style="position: fixed; right: 20px; bottom: 20px; "> -->
      <lottery  ref="lottery" v-if="openlottery" @getOpen="OpenLot" :luckyID ="luckyId" ></lottery>
		<!-- </div> -->
    </div>
  </div>
</template>

<script>
import rightIcon from "@/assets/icons/icon_r.png";
import lottery from "@/components/popup/lottery.vue";
  import Loading from "@/components/loading.vue";
import api from "@/api";
import { share } from "@/utils";
let timeout
export default {
  components: { lottery,Loading},
  data: () => ({
    rightIcon,
    list: [
      // {
      //   id: 1,
      //   title: "#10",
      //   price: "100.236",
      //   num: "3423423",
      //   isProgress: true,
      //   time: "2021.09.12  10:00:00",
      //   name: "tom",
      // },
      // {
      //   id: 2,
      //   title: "#20",
      //   price: "100.246835451936",
      //   num: "8.34534523",
      //   isProgress: false,
      //   time: "2029.09.12  10:00:00",
      //   name: "cs001",
      // },
      // {
      //   id: 3,
      //   title: "#130",
      //   price: "1009846512346.236",
      //   num: "19234.322",
      //   isProgress: false,
      //   time: "2021.02.12  10:00:00",
      //   name: "kendeji",
      // },
      // {
      //   id: 4,
      //   title: "#160",
      //   price: "1009846512346.236",
      //   num: "19000000000000",
      //   isProgress: false,
      //   time: "2021.02.12  10:00:00",
      //   name: "tonny",
      // },
      // {
      //   id: 5,
      //   title: "#1646150",
      //   price: "100984346.236",
      //   num: "19000000000",
      //   isProgress: false,
      //   time: "2021.03.01  10:00:00",
      //   name: "cs",
      // },
    ],
    d1: "0",
    d2: "0",
    h1: "0",
    h2: "0",
    m1: "0",
    m2: "0",
    s1: "0",
    s2: "0",
    openlottery: false,
    TopInfo:"",
    endTimeStamp:"",
    luckyId:'',
    isShowLoading: false,
      pageNumber: 0,
    pageSize: 10,
     isLoading:false,
      share_id: 0,
      TopInfoRule:'',
      isCopy:false,
      confId:''
    
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
   
    window.eventBus.$on('LangChange', this.onLangChange);
    this.getTopInfo();
     this.Time();
  
  },
  methods: {
    onLangChange(){
      this.getTopInfo();
    },
    copyChainLink(txid){
      console.log("eeeeeeeeee")
      if(txid){
          let url = 'https://bscscan.com/tx/'+txid
      window.open(url);
      }

    
    },

    // 获取顶部信息
    async getTopInfo(){
      let activityId = this.$route.query.activityId
      let params = {
     owner: this.$store.state.did,
     activityId:activityId
     //owner: "iaa1dqfet703fj838m9u3yq68h2rczt8fr3e57agal",
    
      };
      let resTopInfo = await api.home.luckInfo(params);
      console.log("wxl --- ",resTopInfo)
      if(resTopInfo.success == true){
         this.TopInfo = resTopInfo.data[0]
      this.endTimeStamp = resTopInfo.data[0].endTime
      this.confId = resTopInfo.data[0].confId
      this.TopInfoRule = JSON.parse(this.TopInfo.rule);
      let lang = localStorage.getItem("KEY_LANG");
      if(lang == "zh"){
      this.TopInfoRule = this.TopInfoRule.zh.replace(/\n/g,"<br/>");
      this.TopInfoRule = this.TopInfoRule.replace("本游戏活动与苹果公司无关。",'')
      }else{
 
      this.TopInfoRule = this.TopInfoRule.en.replace(/\n/g,"<br/>");
      this.TopInfoRule = this.TopInfoRule.replace("This activity has nothing to do with Apple inc.",'')

      }
      }
         this.getList();
     
    },
     share,
    // 获取list
    async getList(){
      if(this.isLoading)
      {
        return;
      }
      this.isLoading = true
      this.isShowLoading = true;
      this.pageNumber++;
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        confId:this.confId
      }
      let resList = await api.home.joinList(params);
      this.totalPage = resList.data.totalPage;
      resList.data.list.forEach(async v => {
                   this.list.push({
                       id: v.id,
                       activityName: v.activityName,
                       userName:v.userName?v.userName:v.irisAddress.substr(0, 6)+"..."+v.irisAddress.substr(-6),
                       prizePool: v.prizePool,
                      activityStatus:v.activityStatus,
                       endTime: this.timestampToTime(v.endTime),
                       txid:v.txid,
                       coinDenom:v.coinDenom
                   });
          if(v.activityStatus == 3)
          {
            setInterval(() => {
            location.reload()
            
          }, 10000);
          }
      })
      this.isShowLoading = false;
      this.isLoading = false
      
      console.log(resList)
      //this.list = 

    },
    	timestampToTime(time) {
	  let date = new Date(time)
	  let Y = date.getFullYear() + '-';
	  let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
	  let D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
	  let h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
	  let m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
	  let s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
	  let strDate = Y+M+D+h+m+s;
	  return strDate;
  },
    getTime() {
      // 摄像作品结束时间
      //let photographyEndTimeStamp = 1646366400000;
      this.now = new Date().valueOf();
      this.end = this.endTimeStamp;

      if (this.now < this.end) {
        this.seconds = (this.end - this.now) / 1000;
      } else {
       clearInterval(timeout);
      }
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      let day = d < 10 ? "0" + d : d;
      this.d1 = String(day).split("")[0];
      this.d2 = String(day).split("")[1];
      //let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
      let h = parseInt((this.seconds / (60 * 60)) % 24);
      let hour = h < 10 ? "0" + h : h;
      this.h1 = String(hour).split("")[0];
      this.h2 = String(hour).split("")[1];
      let m = parseInt((this.seconds / 60) % 60);
      let minutes = m < 10 ? "0" + m : m;
      this.m1 = String(minutes).split("")[0];
      this.m2 = String(minutes).split("")[1];
      let s = parseInt(this.seconds % 60);
      let second = s < 10 ? "0" + s : s;
      this.s1 = String(second).split("")[0];
      this.s2 = String(second).split("")[1];
    },
    //定时器没过1秒参数减1
    Time() {
     timeout =  setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
          this.countDown();
        } else {
          this.getTime();
        }
      }, 1000);
    },
    //打开弹框
     //打开弹框
      toLottery(id){
        console.log("444444444444")
        console.log(id)
        this.luckyId = id
          this.openlottery = true
          this.$refs.lottery.init(this.luckyId);
          //setTimeout(() => { window.eventBus.$emit("ViewMessage", true) }, 1000);
          
      },
    //弹框关闭后获取open值
    OpenLot(e) {
      this.openlottery = e;
    },
    async onScroll(e) {
       
         const {scrollTop, clientHeight, scrollHeight} =
           e.target.scrollingElement;
           console.log(scrollTop,clientHeight,scrollHeight,this.totalPage,this.pageNumber)
         if (scrollTop + clientHeight +10 >= scrollHeight && (this.totalPage > this.pageNumber)) {
            
            await this.getList();
         }  
       
      },
      ToMyLuckyDraw(){
        this.$router.push({ name: "Lucky" });
      },
    shareBtn(id) {
    
      //this.openShare = !this.openShare;
    this.share_id != 0 ? (this.share_id!=id?this.share_id=id:this.share_id=0) : this.share_id = id;
      
    },
    toCopyLink() {
      // http://web.upticknft.com:8084/#/saledetail?id=7666
      var input = document.createElement("input");
      input.value =
        process.env.VUE_APP_BASE_URL +
        `#/saledetail?id=${this.$route.query.id}`;

      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.isCopy = true;
      setTimeout(() => (this.isCopy = false), 100);

      //xxl TODO need to add tip
    },

    toTweet() {
      // alert("abc ...");
      var meta = document.createElement("meta");
      //meta.setAttribute('property', 'og:image');
      meta.setAttribute("name", "twitter:image:src");
      meta.setAttribute(
        "content",
        "https://cdn.cms-twdigitalassets.com/content/dam/developer-twitter/redesign-2021-images/og-social-card/devwebsite_card_tn.jpg.twimg.768.jpg"
      );
      document.getElementsByTagName("head")[0].appendChild(meta);

      //window.open("http://twitter.com/share?text=Im Sharing on Twitter&url=http://stackoverflow.com/users/2943186/youssef-subehi&hashtags=stackoverflow,example,youssefusf")
      window.open(
        "https://twitter.com/intent/tweet?text=Hello%20world&url=http://graphics8.nytimes.com/images/2012/02/19/us/19whitney-span/19whitney-span-articleLarge.jpg"
      );
      //window.open("https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fdeveloper.twitter.com%2F&amp;ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&amp;text=Guides%20%7C%20Docs%20%7C%20Twitter%20Developer%20Platform&amp;url=https%3A%2F%2Fdeveloper.twitter.com%2Fen%2Fdocs%2Ftwitter-for-websites%2Ftweet-button%2Foverview")
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
img {
  display: block;
}
.contain {
  display: flex;
  flex-direction: column;
  .title {
    .alltit {
      display: block;
      position: relative;
      p {
         display:inline-block;
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 25px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: 0px;
        color: #270645;
      }
      .hovershow {
        display: inline-block;
         margin-bottom:-5px;
        width: 22px;
        height: 21px;
        margin-left: 11px;
      }
      .box {
        display: none;
        position: absolute;
        left: 135px;
        top: 35px;
        margin-top: 5px;
        border-radius: 5px;
        background: #270645;
        width: 408px;
        min-height: 100px;
        z-index: 100;
        padding:25px 24px 17px 19px;

        &:before {
          // content: "";
          display: block;
          width: 0;
          height: 0;
          border-width: 0 8px 10px;
          border-style: solid;
          border-color: transparent transparent #270645;
          position: relative;
          top: -15px;
          left: -5px;
          margin-top: -20px;
        }
        div{
          color: #ffffff;
        font-size: 10px;
        line-height: 15px;
        text-align: left;
        }
      }
    }
    .hovershow:hover + .box {
      display: block;
    }
    .cont {
      .desc {
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: 0px;
        color: #270645;
      }
      .linkto {
        span {
          display: block;
          font-family: "\5FAE\8F6F\96C5\9ED1";
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: 0px;
          color: #270645;
        }
        img {
          width: 31px;
          height: 31px;
          margin-left: 15px;
        }
      }
    }
  }
  .progress {
    padding: 23px 33px 42px;
    max-width: 1217px;
    height: 200px;
    background-image: linear-gradient(0deg, #7f1ee2 0%, #3e0881 100%),
      linear-gradient(#6f58d9, #6f58d9);
    background-blend-mode: normal, normal;
    border-radius: 5px;
    .left {
      width: 40%;
      p {
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 37px;
        letter-spacing: 0px;
        color: #ffffff;
      }
      .pricetit {
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 38px;
        letter-spacing: 0px;
        color: #ffdf7e;
        margin-bottom: 25px;
      }
      .pricecont {
        img {
          width: 41px;
          height: 41px;
          margin-right: 24px;
        }
        .price {
          height: 46px;
          font-family: "\5FAE\8F6F\96C5\9ED1";
          font-size: 50px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 0px;
          color: #ffdf7e;
          // box-shadow: 0px 0px 9px 0px
          //     rgba(0, 0, 0, 0.09);
        }
      }
    }
    .center {
      cursor: pointer;
      width: 30%;
      height: 17px;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #ffdf7e;
      margin-top: 8%;
    }
    .right {
      width: 30%;
      text-align: right;
      position: relative;
      p {
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 120px;
        letter-spacing: 0px;
        color: #ffdf7e;
        margin-top: 37px;
      }
      .timer {
        position: absolute;
        right: 0;
        max-width: 337px;
        margin-top: 8px;
        .mao {
          font-family: "\5FAE\8F6F\96C5\9ED1";
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          // line-height: 120px;
          letter-spacing: 0px;
          color: #ffffff;
        }
        .bom {
          font-family: "\5FAE\8F6F\96C5\9ED1";
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          // line-height: 120px;
          letter-spacing: 0px;
          color: #ffdf7e;
          text-align: center;
          margin-top: 7px;
        }
        span {
          display: inline-block;
          width: 30px;
          height: 41px;
          background-color:#480e83;
          border-radius: 5px;
          font-family: "\5FAE\8F6F\96C5\9ED1";
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 41px;
          letter-spacing: 0px;
          color: #ffffff;
          text-align: center;
        }
      }
      
    }
  }
  .list {
    max-width: 1217px;
    height: 101px!important;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#6f58d9, #6f58d9);
    background-blend-mode: normal, normal;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    padding: 24px 31px 29px;
     position:relative;
    .price {
      // max-width:468px;
      .pricetit {
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
        margin-bottom: 0px;
      }
      img {
        width: 21px;
        height: 21px;
        display: block;
        margin-top: 4px;
        margin-right: 15px;
      }
      .pricenum {
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
    }
    .num {
      // max-width:190px;
      margin: 17px 0;
      position:relative;
      img {
        width: 18px;
        height: 18px;
        margin-left: 5px;
      }
    }
    .time {
      margin: 15px -109px 15px 0;
    }
    .link {
      position:relative;
      margin: 15px 0;
    }
    .share {
          width: 166px;
          height: 161px;
          background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#6f58d9, #6f58d9);
          background-blend-mode: normal, normal;
          box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
          border-radius: 5px;
          position: absolute;
           right: 31px;
          top: 63px;
          z-index: 20000;

          img,
          .icon {
            display: inline-block;
            width: 25px;
            height: 25px;
          }

          span {
           font-family:Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
          }
        }
  }

  &.mobile {
    .title {
      .alltit {
        height: 25px;
        margin-bottom: 8px;
        p {
          font-size: 18px;
        }
        .box{
          width: 340px;
          position: absolute;
        left: 0px;
        top: 35px;
          &:before{
             position: relative;
          top: -15px;
          left: 84px;
          }
        }
      }
      .cont {
        font-size: 15px;
        line-height: 15px;
      }
    }
    .progress {
      height: 388px;
      .left {
        width: 100%;
        text-align: center;
        .pricetit {
          margin-bottom: 30px;
        }
        .pricecont {
          img {
            margin-left: 42%;
            width: 48px;
            height: 48px;
            margin-bottom: 28px;
          }
          .price {
            text-align: center;
            width: 100%;
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 41px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 36px;
            letter-spacing: 0px;
            color: #ffdf7e;
            // box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.09);
            margin-bottom: 36px;
          }
        }
      }
      .right {
        width: 100% !important;
        height: 73px;
        p {
          margin-bottom: 14px;
          margin-top: 0;
          text-align: center;
          height: 11px;
          font-family: "\5FAE\8F6F\96C5\9ED1";
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffdf7e;
        }
        .timer {
          position: absolute;
          left: -3px;
          margin-bottom: 30px;
          span {
            width: 25px;
            height: 33px;
            line-height: 33px;
            margin-right: 3px;
          }
        }
      }
    }
    .mobilelist{
       .list1 {
      // max-width: 1217px;
      height: 130px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#6f58d9, #6f58d9);
      background-blend-mode: normal, normal;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
      border-radius: 5px;
      padding: 24px 31px 29px;
      .top {
        height:45px;
        .price {
          // max-width:468px;
          .pricetit {
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
            margin-bottom: 0px;
          }
          img {
            width: 20px;
            height: 20px;
            display: block;
            margin-top: 4px;
            margin-right: 15px;
          }
          .pricenum {
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 19px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
          }
        }
        .num {
          // max-width:190px;
          margin: 17px 0;
          text-align:right;
          img {
            width: 18px;
            height: 18px;
            margin-left: 5px;
          }
          .load{
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
          }
        }
      }
      .bottom {
          position: relative;
        .time {
          margin: 15px 0;
          img{
            width:21px;
            height:21px;
            margin-right:9px;
          }
        }
        .link {
          margin: 15px 0;
        }
          .share {
            width: 166px;
            height: 161px;
            background-image: linear-gradient(#ffffff, #ffffff),
              linear-gradient(#6f58d9, #6f58d9);
            background-blend-mode: normal, normal;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 5px;
            position: absolute;
            left: 150px;
            top: 50px;
            z-index: 20000;

            img,
            .icon {
              display: inline-block;
              width: 25px;
              height: 25px;
            }

            span {
             font-family:""\5FAE\8F6F\96C5\9ED1"";
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0px;
              color: #270645;
            }
          }
      }
    }
    }

  }
}
</style>

